import { OptionElement } from '@partnerconnect-monorepo/types';
import { FlaUserRoleLabel, ReportingUserRoleLabel } from '../constants';
import { t } from '@transifex/native';

export const getRolesLabel = (roles: Array<OptionElement> | undefined) =>
  roles?.map(({ label }) => t(label)).join('/');

export const getRolesValue = (roles: Array<OptionElement> | undefined) =>
  roles?.map(({ value }) => t(value)).join('/');

const userRoleLabelFallback = (value: string, label: string) => {
  console.error('A string has not been translated: ', value);
  return label;
};

export const getReportingUserRoleOptions = (
  roles: Array<OptionElement> | undefined
) =>
  roles?.map(({ value, label }) => ({
    value,
    label: ReportingUserRoleLabel[value] ?? userRoleLabelFallback(value, label)
  })) ?? [];

export const getFlaUserRoleOptions = (
  roles: Array<OptionElement> | undefined
) =>
  roles?.map(({ value, label }) => ({
    value,
    label: FlaUserRoleLabel[value] ?? userRoleLabelFallback(value, label)
  })) ?? [];
