import { render, RenderOptions } from '@testing-library/react';
import { PropsWithChildren, ReactElement } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import {
  OnlineStatusProvider,
  SnackbarContextProvider,
  UserProfileProvider,
  RoutesContextProvider,
  ContextsProvider,
  useEnv
} from '../context';
import { ComposeContexts } from '../core/ComposeProviders';
import { RoutesList } from '../core/RoutesList';
import { FlagsProvider } from 'flagged';
import { FeatureFlag } from '../constants';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false
    }
  }
});

export const Providers = ({ children }: PropsWithChildren) => {
  const env = useEnv();

  return (
    <FlagsProvider
      features={{
        [FeatureFlag.FlaAmendment]: true,
        [FeatureFlag.PreferencesPage]: true,
        [FeatureFlag.ReportingOverviewPage]: true,
        [FeatureFlag.NfrDigitalSigning]: true,
        [FeatureFlag.SpotcheckModule]: true
      }}
    >
      <QueryClientProvider client={client}>
        <OnlineStatusProvider>
          <SnackbarContextProvider hideProgressBar>
            <AuthProvider>
              <UserProfileProvider>
                <BrowserRouter basename={env.reactAppBaseRoute}>
                  <RoutesContextProvider>
                    <ContextsProvider>
                      <ComposeContexts>
                        {children}
                        <RoutesList />
                      </ComposeContexts>
                    </ContextsProvider>
                  </RoutesContextProvider>
                </BrowserRouter>
              </UserProfileProvider>
            </AuthProvider>
          </SnackbarContextProvider>
        </OnlineStatusProvider>
      </QueryClientProvider>
    </FlagsProvider>
  );
};

const customRender = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'wrapper'>
) => render(ui, { wrapper: Providers, ...options });

export * from '@testing-library/react';
export { customRender as render };
