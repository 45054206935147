import { useEnv, useUserProfile } from '../../../context';
import { useAuth } from 'react-oidc-context';
import { useCurrentLocale, useSigninRedirect } from '../../../hooks';
import { useFeature } from 'flagged';
import { useMemo } from 'react';
import { hasPermission } from '../../../helpers';
import {
  Permission,
  getFlaUserRoleOptions,
  getRolesLabel,
  getReportingUserRoleOptions
} from '../../../utils';
import { useLocation } from 'react-router-dom';
import { FeatureFlag } from '../../../constants';
import { App } from '@partnerconnect-monorepo/types';

export const useNavbar = () => {
  const { signoutRedirect, user } = useAuth();

  const { signinRedirect } = useSigninRedirect();

  const { userProfile, username } = useUserProfile();

  const { locale, languages, setLocale } = useCurrentLocale();

  const isPreferencesPageEnabled = useFeature(FeatureFlag.PreferencesPage);

  const canAccessInvoicing = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanAccessInvoicing
      ),
    [userProfile?.permissions]
  );

  const canAccessPartnershipsAndFinancesUsers = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanAccessPartnershipsUsers
      ),
    [userProfile?.permissions]
  );

  const location = useLocation();

  const isUsersManagementAccessed = useMemo(
    () =>
      location.pathname.includes('/users') ||
      location.pathname.includes('/partner-users'),
    [location]
  );

  const canAccessReportingUsers = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewPartnerProfiles
      ),
    [userProfile?.permissions]
  );

  const userRolesTitle = useMemo(() => {
    const { reportingRoles, flaRoles } = userProfile || {};

    return (
      getRolesLabel(getReportingUserRoleOptions(reportingRoles ?? [])) ||
      getRolesLabel(getFlaUserRoleOptions(flaRoles ?? [])) ||
      ''
    );
  }, [userProfile]);

  const userFullName =
    userProfile?.firstName && userProfile?.lastName
      ? `${userProfile?.firstName} ${userProfile?.lastName}`
      : username;

  const canAccessReporting = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewMonthlyReports
      ),
    [userProfile?.permissions]
  );

  const canAccessPartnershipOpportunities = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewPartnershipOpportunity
      ),
    [userProfile?.permissions]
  );

  const canAccessSpotcheck =
    !!useFeature(FeatureFlag.SpotcheckModule) &&
    hasPermission(
      userProfile?.permissions ?? [],
      Permission.CanAccessSpotcheck
    );

  const { appName } = useEnv();

  const isPartnershipsModuleAccessed = [App.Fla, App.Spotcheck].includes(
    appName
  );

  return {
    logout: () => signoutRedirect({ id_token_hint: user?.id_token }),
    userProfile,
    userRolesTitle,
    setLocale,
    locale,
    languages,
    username: userFullName,
    signinRedirect,
    canAccessInvoicing,
    canAccessPartnershipsAndFinancesUsers,
    canAccessReportingUsers,
    isUsersManagementAccessed,
    isPreferencesPageEnabled,
    canAccessReporting,
    canAccessPartnershipOpportunities,
    canAccessSpotcheck,
    isPartnershipsModuleAccessed
  };
};
