import { t } from '@transifex/native';

export enum Role {
  WfpViewer = 'wfp_viewer',
  WfpAdmin = 'wfp_admin',
  WfpGlobalAdmin = 'wfp_global_admin',
  WfpApprover = 'wfp_approver',
  WfpSupport = 'wfp_support',
  WfpVerifier1 = 'wfp_verifier_1',
  WfpVerifier2 = 'wfp_verifier_2',
  PartnerDataCollector = 'partner_data_collector',
  PartnerEditor = 'partner_editor',
  PartnerApprover = 'partner_approver',
  WfpFlaAdmin = 'wfp_fla_admin',
  WfpInvoicingFocalPoint = 'wfp_invoicing_focal_point',
  PartnerFlaEditor = 'partner_fla_editor',
  PartnerInvoicingFocalPoint = 'partner_invoicing_focal_point',
  WfpSpotcheckAdmin = 'wfp_spot_check_admin',
  WfpSpotcheckViewer = 'wfp_spot_check_viewer',
  WfpSpotcheckGlobalAdmin = 'wfp_spot_check_global_admin',
  WfpPartnerAdmin = 'wfp_partner_admin'
}

export const ReportingUserRoleLabel: Record<string, string> = {
  [Role.WfpViewer]: t('WFP viewer'),
  [Role.WfpAdmin]: t('WFP admin'),
  [Role.WfpGlobalAdmin]: t('WFP global admin'),
  [Role.WfpApprover]: t('WFP approver'),
  [Role.WfpSupport]: t('WFP support'),
  [Role.WfpVerifier1]: t('WFP verifier 1'),
  [Role.WfpVerifier2]: t('WFP verifier 2'),
  [Role.PartnerDataCollector]: t('Partner data collector'),
  [Role.PartnerEditor]: t('Partner editor'),
  [Role.PartnerApprover]: t('Partner approver'),
  [Role.WfpPartnerAdmin]: t('WFP partner admin')
};

export const FlaUserRoleLabel: Record<string, string> = {
  ...ReportingUserRoleLabel,
  [Role.WfpAdmin]: t('WFP admin'),
  [Role.WfpApprover]: t('WFP FLA approver'),
  [Role.PartnerEditor]: t('FLA partner editor'),
  [Role.WfpFlaAdmin]: t('WFP FLA admin'),
  [Role.WfpInvoicingFocalPoint]: t('WFP invoicing focal point'),
  [Role.PartnerFlaEditor]: t('FLA partner editor'),
  [Role.PartnerInvoicingFocalPoint]: t('Partner invoicing focal point'),
  [Role.WfpSpotcheckAdmin]: t('Spot-check admin'),
  [Role.WfpSpotcheckViewer]: t('Spot-check viewer'),
  [Role.WfpSpotcheckGlobalAdmin]: t('Global spot-check admin')
};
