import { App, EnvironmentVariables } from '@partnerconnect-monorepo/types';

export const environment: EnvironmentVariables = {
  production: true,
  appName: App.Home,
  baseUrl: process.env.NX_REPORTING_APP_BE_BASE || '',
  publicUrl: process.env.NX_REACT_APP_PUBLIC_URL || '',
  environment: process.env.NX_REACT_APP_ENV || '',
  authUrl: process.env.NX_REACT_APP_AUTHENTICATION_URL || '',
  logoutUrl: process.env.NX_REACT_APP_LOGOUT_URL || '',
  authenticationClientId:
    process.env.NX_REACT_APP_AUTHENTICATION_CLIENT_ID || '',
  authRedirectUrl: process.env.NX_REACT_APP_CIAM_REDIRECT_URI || '',
  authLogoutRedirectUrl: process.env.NX_REACT_APP_CIAM_LOGOUT_REDIRECT || '',
  transifexToken: process.env.NX_REACT_APP_TRANSIFEX_TOKEN || '',
  sentryDsn: process.env.NX_REACT_APP_SENTRY_DSN || '',
  reactAppBaseRoute: process.env.NX_HOME_APP_BASE_ROUTE || '/',
  enableFlaAmendment: process.env.NX_REACT_APP_ENABLE_FLA_AMENDMENT === 'true',
  supportedLanguages: process.env.NX_REACT_APP_SUPPORTED_LANGUAGES || '',
  enableReportingOverviewPage:
    process.env.NX_REACT_APP_ENABLE_REPORTING_OVERVIEW_PAGE === 'true',
  enablePreferencesPage:
    process.env.NX_REACT_APP_ENABLE_PREFERENCES_PAGE === 'true',
  enableNfrDigitalSigning:
    process.env.NX_REACT_APP_ENABLE_NFR_DIGITAL_SIGNING === 'true',
  enableSpotcheckModule:
    process.env.NX_REACT_APP_ENABLE_SPOTCHECK_MODULE === 'true'
};
