import { hasPermission } from '../../helpers';
import { useUserProfile } from '../../context';
import { PropsWithChildren, useMemo } from 'react';
import { FeatureFlag, NO_ACCESS_PATH } from '../../constants';
import { useFeature } from 'flagged';
import { AppBaseHref } from '@partnerconnect-monorepo/types';

export const PrivateRoute = ({
  children,
  permissionCheck
}: PropsWithChildren<{ permissionCheck: string | null }>) => {
  const { userProfile } = useUserProfile();

  const canAccessReporting = useMemo(
    () =>
      window.location.pathname.includes(AppBaseHref.Reporting) &&
      userProfile?.allowedModules?.canAccessReporting,
    [userProfile?.allowedModules?.canAccessReporting]
  );

  const canAccessPartnerships = useMemo(
    () =>
      window.location.pathname.includes(AppBaseHref.Fla) &&
      userProfile?.allowedModules?.canAccessPartnerships,
    [userProfile?.allowedModules?.canAccessPartnerships]
  );

  const canAccessInvoicing = window.location.pathname.includes(
    AppBaseHref.Invoicing
  );

  const isSpotcheckModuleEnabled = !!useFeature(FeatureFlag.SpotcheckModule);

  const canAccessSpotchecks =
    window.location.pathname.includes(AppBaseHref.Spotcheck) &&
    isSpotcheckModuleEnabled;

  const isAccessingUnprotectedPathname = useMemo(
    () =>
      [
        AppBaseHref.Reporting,
        AppBaseHref.Fla,
        AppBaseHref.Invoicing,
        AppBaseHref.Spotcheck
      ].every((pathname) => !window.location.pathname.includes(pathname)),
    []
  );

  const canAccessProtectedModule =
    canAccessReporting ||
    canAccessPartnerships ||
    canAccessInvoicing ||
    canAccessSpotchecks;

  const canAccess = useMemo(
    () =>
      userProfile &&
      (canAccessProtectedModule || isAccessingUnprotectedPathname) &&
      permissionCheck
        ? hasPermission(userProfile?.permissions, permissionCheck)
        : false,
    [
      canAccessProtectedModule,
      isAccessingUnprotectedPathname,
      permissionCheck,
      userProfile
    ]
  );

  if (!canAccess) window.location.replace(NO_ACCESS_PATH);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return canAccess ? <>{children}</> : null;
};
